import Vue from 'vue';
import api from '../../api';
import * as _ from 'lodash';
import {HARDWARE_MANUFACTURER, HARDWARE_MODEL_NUMBER, HARDWARE_PRODUCT_LINE, IHardware, IHeader} from '@animtools/rdx-common';
import {NO_VALUE_FIELD} from '../../constants/datapicker';

let axis;
try {
  axis = JSON.parse(localStorage.getItem('hardware-graph-axis'));
} catch (e) {
  axis = {};
}
export const hardwareModule = {
  namespaced: true,

  state: {
    motors: {
      data: [],
      loading: true,
      changes: {},
    },
    encoders: {
      data: [],
      loading: true,
      changes: {},
    },
    drives: {
      data: [],
      loading: true,
      changes: {},
    },
    gearboxes: {
      data: [],
      loading: true,
      changes: {},
    },
    axis,

    motorsIndex: new Map(),
    encodersIndex: new Map(),
    drivesIndex: new Map(),
    gearboxesIndex: new Map(),

    selectedHardwareListType: 'motor',

    editedItem: {},

    defaultTableFields: [
      HARDWARE_MODEL_NUMBER,
      HARDWARE_MANUFACTURER,
      HARDWARE_PRODUCT_LINE,
    ],

    selectedTableFields: {
      motor: [],
      encoder: [],
      drive: [],
      gearbox: [],
    },

    hardwareHistory: {
      motors: {},
      encoders: {},
      drives: {},
      gearboxes: {},
    },
    historyDialog: {
      isOpen: false,
      field: null,
      item: null,
    },
  },

  getters: {
    // TODO: This shouldn't need to be done.
    // At the very least there should be a switch here to only pass back "approved" state.
    getStateByType: (state) => (type) => {
      return state[type];
    },

    getXAxis: (state) => (type) => state.axis?.[type]?.x,
    getYAxis: (state) => (type) => state.axis?.[type]?.y,
    getRAxis: (state) => (type) => state.axis?.[type]?.r,
    getNameDisplay: (state) => (type) => state.axis?.[type]?.name,

    isFieldChanged: (state) => (type, item, field) => {
      const key = `${item.uuid}_${field.value}`;
      return state[type].changes[key] != null;
    },

    numberOfChangesForItem: (state) => (type, item) => {
      const changeKeys = Object.keys(state[type].changes);
      const itemChanges = changeKeys.filter((change) => state[type].changes[change] && change.startsWith(item.uuid));
      return itemChanges.length;
    },

    numberOfChangesByType: (state) => (type) => {
      const changeKeys = Object.keys(state[type].changes || {});
      const itemChanges = changeKeys.filter((change) => state[type].changes[change]);
      return itemChanges.length;
    },
  },

  actions: {
    editItem({commit}, item) {
      commit('setEditedItem', item);
      commit('setDialog', true, {root: true});
    },

    async hardwareAdd(unused, {hardwareType, hardware}) {
      return api.addHardware(hardwareType, hardware);
    },

    async hardwareEdit(unused, {hardwareType, uuid, changes}) {
      return api.editHardware(hardwareType, uuid, changes);
    },

    hardwareDelete(unused, {hardwareType, hardware}) {
      return api.deleteHardware(hardwareType, hardware);
    },

    saveAxis({commit}, {axis, type, value}) {
      commit('setAxis', {axis, type, value});
    },

    setHistoryDialog({ commit }, { item, field, isOpen }) {
      commit('setHistoryDialogItem', item);
      commit('setHistoryDialogField', field);
      commit('setHistoryDialogIsOpen', isOpen);
    },

    async getHardwareHistory({commit}, {hardwareType, uuid}) {
      try {
        const response = await api.getHardwareHistory(hardwareType, uuid);
        commit('setHardwareHistory', {hardwareType, uuid, data: response.data});
      } catch (error) {
        console.error(error);
        commit('pushError', error, {root: true});
      }
    },

    async getHardwareFieldHistory({commit}, {hardwareType, uuid, field}) {
      try {
        const response = await api.getHardwareFieldHistory(hardwareType, uuid, field)
        commit('setHardwareFieldHistory', {hardwareType, uuid, data: response.data, field});
      } catch (error) {
        console.error(error);
        commit('pushError', error, {root: true});
      }
    },
  },

  mutations: {
    setEditedItem(state, item) {
      state.editedItem = item;
    },

    setAxis(state, {axis, type, value}) {
      state.axis = {
        ...state.axis,
        [type]: {
          ...state.axis?.[type],
          [axis]: value,
        },
      };
      localStorage.setItem('hardware-graph-axis', JSON.stringify(state.axis));
    },

    changeColumnValueInTable(state, {originalField, field}) {
      const selectedTableFields = state.selectedTableFields[state.selectedHardwareListType];
      if (!originalField?.value) {
        selectedTableFields.push({
          text: field.text,
          value: field.value,
        });
      } else {
        const fieldIndexToChange = selectedTableFields.findIndex((column) => column.value === originalField.value);
        const updatedColumn = {
          text: field.text,
          value: field.value,
        };
        Vue.set(selectedTableFields, fieldIndexToChange, updatedColumn);
      }
    },

    removeColumnFromTable(state, header: IHeader) {
      const selectedTableFields = state.selectedTableFields[state.selectedHardwareListType];
      const indexToRemove = selectedTableFields.findIndex((field) => field.value === header?.value ?? NO_VALUE_FIELD);
      selectedTableFields.splice(indexToRemove, 1);
    },

    setSelectedHardwareListType(state, type) {
      state.selectedHardwareListType = type;
    },

    setHardwareChange(state, {type, item, field, value}) {
      const index = state[type].data.findIndex((i) => i.uuid == item.uuid);
      if (index === -1) {
        console.log(`Could not find ${type}`, item);
        return;
      }
      const changeKey = `${item.uuid}_${field.value}`;
      const changes = state[type].changes[changeKey];
      const originalValue = changes ?
        changes.originalValue : _.cloneDeep(state[type].data[index][field.value]);

      let changeValue = null;
      if (!_.isEqual(value, originalValue)) {
        changeValue = {
          originalValue,
          newValue: value,
        };
      }

      Vue.set(state[type].data[index], field.value, value);
      if (changeValue == null) {
        Vue.delete(state[type].changes, changeKey);
      } else {
        Vue.set(state[type].changes, changeKey, changeValue);
      }
    },

    undoChangeByType(state, type) {
      Object.keys(state[type].changes)
        .map(key => {
          const [uuid, field] = key.split('_');
          return { uuid, field, key };
        })
        .forEach(({ uuid, field, key }) => {
          const index = state[type].data
            .findIndex((item) => item.uuid === uuid);
          const { originalValue } = state[type].changes[key]
          Vue.set(state[type].data[index], field, originalValue);
        });

      Vue.set(state[type], 'changes',  {});
    },

    clearCommittedChanges(state, type) {
      Vue.set(state[type], 'changes',  {});
      state.changelog = '';
    },

    setHardwareHistory(state, {hardwareType, uuid, data}) {
      state.hardwareHistory[hardwareType][uuid] = data;
    },

    setHardwareFieldHistory(state, {hardwareType, uuid, field, data}) {
      if (!state.hardwareHistory[hardwareType][uuid]) {
        Vue.set(state.hardwareHistory[hardwareType], uuid, {});
      }
      state.hardwareHistory[hardwareType][uuid][field] = data;
    },

    setHistoryDialogItem(state, newValue: IHardware) {
      Vue.set(state.historyDialog, 'item', newValue);
    },

    setHistoryDialogField(state, newValue: IHeader) {
      Vue.set(state.historyDialog, 'field', newValue);
    },

    setHistoryDialogIsOpen(state, newValue: boolean) {
      Vue.set(state.historyDialog, 'isOpen', newValue);
    },

    // ----------------------------
    // Socket Messages
    // ----------------------------
    SOCKET_MOTORS_SET_ALL(state, motors) {
      state.motors.loading = false;
      state.motors.data = motors;
      state.motorsIndex = new Map(motors.map((motor) => [motor.uuid, motor]));
    },
    SOCKET_ENCODERS_SET_ALL(state, encoders) {
      state.encoders.loading = false;
      state.encoders.data = encoders;
      state.encodersIndex = new Map(
          encoders.map((encoder) => [encoder.uuid, encoder]),
      );
    },
    SOCKET_DRIVES_SET_ALL(state, drives) {
      state.drives.loading = false;
      state.drives.data = drives;
      state.drivesIndex = new Map(drives.map((drive) => [drive.uuid, drive]));
    },
    SOCKET_GEARBOXES_SET_ALL(state, gearboxes) {
      state.gearboxes.loading = false;
      state.gearboxes.data = gearboxes;
      state.gearboxesIndex = new Map(gearboxes.map((gearbox) => [gearbox.uuid, gearbox]),
      );
    },

    SOCKET_MOTOR_CREATED(state, motor) {
      state.motors.data.push(motor);
      state.motorsIndex.set(motor.uuid, motor);
    },
    SOCKET_ENCODER_CREATED(state, encoder) {
      state.encoders.data.push(encoder);
      state.encodersIndex.set(encoder.uuid, encoder);
    },
    SOCKET_DRIVE_CREATED(state, drive) {
      state.drives.data.push(drive);
      state.drivesIndex.set(drive.uuid, drive);
    },
    SOCKET_GEARBOX_CREATED(state, gearbox) {
      state.gearboxes.data.push(gearbox);
      state.gearboxesIndex.set(gearbox.uuid, gearbox);
    },

    SOCKET_MOTOR_UPDATED(state, motor) {
      const index = state.motors.data.findIndex((i) => i.uuid === motor.uuid);
      if (index === -1) {
        console.log('Could not find motor', motor);
        return;
      }
      Vue.set(state.motors.data, index, motor);
    },
    SOCKET_ENCODER_UPDATED(state, encoder) {
      const index = state.encoders.data.findIndex((i) => i.uuid === encoder.uuid);
      if (index === -1) {
        console.log('Could not find encoder', encoder);
        return;
      }
      Vue.set(state.encoders.data, index, encoder);
    },
    SOCKET_DRIVE_UPDATED(state, drive) {
      const index = state.drives.data.findIndex((i) => i.uuid === drive.uuid);
      if (index === -1) {
        console.log('Could not find drive', drive);
        return;
      }
      Vue.set(state.drives.data, index, drive);
    },
    SOCKET_GEARBOX_UPDATED(state, gearbox) {
      const index = state.gearboxes.data.findIndex((i) => i.uuid === gearbox.uuid);
      if (index === -1) {
        console.log('Could not find gearbox', gearbox);
        return;
      }
      Vue.set(state.gearboxes.data, index, gearbox);
    },

    SOCKET_MOTOR_DELETED(state, motor) {
      const index = state.motors.data.findIndex((i) => i.uuid === motor.uuid);
      if (index === -1) {
        console.log('Could not find motor', motor);
        return;
      }
      state.motorsIndex.delete(motor.uuid);
      Vue.delete(state.motors.data, index);
    },
    SOCKET_ENCODER_DELETED(state, encoder) {
      const index = state.encoders.data.findIndex((i) => i.uuid === encoder.uuid);
      if (index === -1) {
        console.log('Could not find encoder', encoder);
        return;
      }
      state.encodersIndex.delete(encoder.uuid);
      Vue.delete(state.encoders.data, index);
    },
    SOCKET_DRIVE_DELETED(state, drive) {
      const index = state.drives.data.findIndex((i) => i.uuid === drive.uuid);
      if (index === -1) {
        console.log('Could not find drive', drive);
        return;
      }
      state.drivesIndex.delete(drive.uuid);
      Vue.delete(state.drives.data, index);
    },
    SOCKET_GEARBOX_DELETED(state, gearbox) {
      const index = state.gearboxes.data.findIndex((i) => i.uuid === gearbox.uuid);
      if (index === -1) {
        console.log('Could not find gearbox', gearbox);
        return;
      }
      state.gearboxesIndex.delete(gearbox.uuid);
      Vue.delete(state.gearboxes.data, index);
    },
  },
};
