export default {
  data: () => ({
    shortTime: {
      hourCycle: 'h12',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneName: 'short',
      hour: '2-digit',
      minute: '2-digit',
    },
    longDate: {
      dateStyle: 'long',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    shortDate: {
      dateStyle: 'short',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }),
  methods: {
    getShortTextDate(changeDate) {
      const date = new Date(changeDate);
      return date.toLocaleDateString('en-US', this.shortDate);
    },
    getLongTextDate(changeDate) {
      const todayDate = new Date();
      const yesterdayDate = new Date();
      const date = new Date(changeDate).toLocaleDateString('en-US', this.longDate);
      yesterdayDate.setDate(todayDate.getDate() - 1);

      const today = todayDate.toLocaleDateString('en-US', this.longDate);
      const yesterday = yesterdayDate.toLocaleDateString(
          'en-US',
          this.longDate,
      );

      if (date === today) {
        return 'Today';
      } else if (date === yesterday) {
        return 'Yesterday';
      }

      return date;
    },
    getTextTime(changeDateString) {
      const date = new Date(changeDateString);
      return this.isValidDate(date) ?
        date.toLocaleTimeString('en-US', this.shortTime) :
        '';
    },
    isValidDate(d: Date) {
      let date = d;
      if (typeof d === 'string') {
        date = new Date(d);
      }
      return date instanceof Date && !isNaN(date.getTime());
    },
  },
};
