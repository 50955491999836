<template>
  <div>
    <v-menu content-class="notification-center-menu" :close-on-content-click="false" v-model="menu" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          :style="notificationsMenuStyle"
          v-on="on"
          icon
        >
          <div>
            <v-icon>
              mdi-bell-outline
            </v-icon>
            <span v-if="numberOfUnreadNotifications > 0" class="unread-messages">{{numberOfUnreadNotifications}}</span>
          </div>
        </v-btn>
      </template>

      <div class="title">
        Notifications
        <v-menu content-class="more-details-menu" rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="more-detail-icon" v-bind="attrs" v-on="on" icon>
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="markAllAsRead()">Mark all as read</v-list-item>
            <v-list-item @click="clearAllNotifications()">Clear all notifications</v-list-item>
            <!-- <v-list-item>Notification settings</v-list-item> -->
          </v-list>
            </v-menu>
      </div>
      <div v-if="Object.keys(notificationsByDate).length === 0" class="empty-notification">
        <div class="notification-title">
          All clear!
        </div>
        <div class="notification-text">
          You have no new notifications right now
        </div>
      </div>
      <div v-for="date in Object.keys(notificationsByDate)" :key="date">
        <div class="date-header">
          {{getLongTextDate(date)}}
        </div>
        <div v-for="notification in notificationsByDate[date]" :key="notification.id" class="notification-row" @click="selectedNotification(notification)">
          <div class="figure-header">
            <span class="flex-grow-1">{{notification.figure.name}}</span>
            <span class="pull-right">{{ getTextTime(notification.createdAt) }}</span>
            <v-menu content-class="more-details-menu" rounded="lg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon class="more-detail-icon">
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="notificationActionTitle(notification)" @click="performNotificationAction(notification)">{{notificationActionTitle(notification)}}</v-list-item>
                <v-list-item v-if="notification.read" @click="markAsUnread(notification)" >Mark as unread</v-list-item>
                <v-list-item v-else @click="markAsRead(notification)" >Mark as read</v-list-item>
                <v-list-item @click="deleteANotification(notification)">Clear notification</v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="notification-body">
            <v-icon v-if="!notification.read" class="unread-notification-icon">
              mdi-circle
            </v-icon>
            <div :style="notification.read?'margin-left:18px;':''">
              <div class="notification-title">
                {{notification.title}}
              </div>
              <div class="notification-text">
                {{notification.body}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from 'vuex';
import datetimeMixin from '../../../mixins/datetimeMixin';

export default {
  name: 'NotificationCenter',
  mixins: [datetimeMixin],
  props: {
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapState('users', ['notifications']),
    notificationsByDate() {
      const notifications = {};
      const sorted = this.notifications.data.toSorted((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      sorted.forEach((notification) => {
        const createdAtDate = new Date(notification.createdAt);
        const key = createdAtDate.toLocaleDateString('en-US', this.longDate);
        if (notifications[key]) {
          notifications[key]?.push(notification);
        } else {
          notifications[key] = [notification];
        }
      })
      return notifications
    },
    numberOfUnreadNotifications() {
      return this.notifications.data.filter((notification) => !notification.read).length
    },
    notificationsMenuStyle() {
      return this.numberOfUnreadNotifications > 0 ? 'padding-left: 15px; margin-right: 10px;' : '';
    },
  },
  methods: {
    ...mapActions('users', ['getNotifications', 'deleteNotification', 'setNotificationReadStatus', 'setAllNotificationsAsRead', 'deleteAllNotifications']),

    async selectedNotification(notification) {
      await this.setNotificationReadStatus({notificationId: notification.id, markAsRead: true})
      this.performNotificationAction(notification)
    },

    notificationActionTitle(notification) {
      if (notification.type === 'documentUpload' || notification.type === 'permissionApproved') {
       return 'Go to figure'
      } else if (notification.type === 'figureCommit') {
        return "Go to figure history"
      }
      return undefined
    },

    performNotificationAction(notification) {
      this.menu = false
      let path = ''
      if (notification.type === 'documentUpload' || notification.type === 'permissionApproved') {
        // Go to the figure page
        path = `/figure/${notification.figureUuid}`
      } else if (notification.type === 'figureCommit') {
        // Go to the figure history page
        path = `/figure/history/${notification.figureUuid}`
      } else if (notification.type === 'permissionRequest') {
        // Go to the figure history page
        path = `/admin/pending`
      }

      if (path && this.$route.path !== path) {
        this.$router.push(path)
      } else {
        this.$router.go()
      }
    },

    markAsRead(notification) {
      this.setNotificationReadStatus({notificationId: notification.id, markAsRead: true})
    },

    markAllAsRead() {
      this.setAllNotificationsAsRead()
    },

    clearAllNotifications() {
      this.deleteAllNotifications()
    },

    markAsUnread(notification) {
      this.setNotificationReadStatus({notificationId: notification.id, markAsRead: false})
    },

    deleteANotification(notification) {
      this.deleteNotification(notification.id)
    }
  },
  async created() {
    await this.getNotifications();
  },
};
</script>
<style lang="scss" scoped>

.unread-messages {
  padding: 2px 7px;
  border-radius: 14px;
  background-color: #0079BC;
  position: relative;
  top: -10px;
  left: -10px;
}
.notification-center-menu {
  border-radius: 8px;
  background: white;
  width: 400px;
  max-height: 800px;
  top: 55px !important;
  overflow: auto;
  position: fixed;
  overscroll-behavior: contain;

  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border-bottom: 1px solid #D0D7DE;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }

  .date-header {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    background: #F5F5F6;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .notification-title {
    font-size: 14px;
    color: #063F69;
    font-style: normal;
    font-weight: 700;
  }

  .notification-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .empty-notification {
    padding: 10px 12px;
  }

  .notification-row {
    cursor: pointer;

    .figure-header {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: rgba(16, 24, 31, 0.87);
      padding: 6px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .more-detail-icon {
        color: #0079BC;
      }
    }

    .notification-body {
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 12px;
      display: flex;
      gap: 10px;
      align-items: flex-start;

      .unread-notification-icon {
        font-size: 8px;
        padding-top: 6px;
        color: #063F69;
      }
    }
  }

  .notification-row:hover {
    background-color: rgba(0, 121, 188, 0.08);
  }
}

.more-details-menu {
  .v-sheet {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .v-list-item {
    margin: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    min-height: 40px;
    cursor: pointer;
  }

  .v-list-item:hover {
    background: rgba(0, 35, 60, 0.04);
  }
}
</style>