import api from '../../api';

export const astrolabeModule = {
  namespaced: true,

  state: {
    profilePhotos: [],
    profiles: []
  },

  getters: {
    profilePhoto(state) {
      return (rdxId) => {
        return state.profilePhotos.find((record) => record.rdxId === rdxId)?.url || null;
      };
    },
    profile(state) {
      return (rdxId) => {
        return state.profiles.find((record) => record.rdxId === rdxId) || null;
      };
    },
  },

  mutations: {
    addProfilePhotos(state, profilePhotos) {
      state.profilePhotos = profilePhotos.concat(state.profilePhotos);
    },
    addProfiles(state, profiles) {
      state.profiles = profiles.concat(state.profiles);
    },
  },

  actions: {
    async getAstrolabePhotoUrls({commit, getters}, userIds : string[]) {
      try {
        const filteredUserIds = userIds.filter((userId) => !getters.profilePhoto(userId));
        const batchSize = 100;
        const totalBatches = Math.ceil(filteredUserIds.length / batchSize);
        for (let i = 0; i < totalBatches; i++) {
          const start = i * batchSize;
          const end = start + batchSize;
          const batch = filteredUserIds.slice(start, end);
          const result = (await api.getAstrolabePhotoUrl(batch)).data;
          if (result) {
            commit('addProfilePhotos', result);
          }
        }
      } catch (ex) {
        console.error('Unable to get Astrolabe profile photos.', ex);
        commit('pushError', ex, {root: true});
      }
    },
    async getAstrolabeProfiles({commit, getters}, userIdsList : string[]) {
      try {
        const filteredUserIds = userIdsList.filter((userId) => !getters.profile(userId));
        const batchSize = 100;
        const totalBatches = Math.ceil(filteredUserIds.length / batchSize);

        for (let i = 0; i < totalBatches; i++) {
          const start = i * batchSize;
          const end = start + batchSize;
          const batch = filteredUserIds.slice(start, end);

          const result = (await api.getAstrolabeProfiles(batch)).data;
          if (result) {
            commit('addProfiles', result);
            commit('addProfilePhotos', result.map((record) => ({rdxId: record.rdxId, url: record.profilePhotoUrl})));
          }
        }
      } catch (ex) {
        console.error('Unable to get Astrolabe profiles.', ex);
        commit('pushError', ex, {root: true});
      }
    },
  },
};
