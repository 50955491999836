import Vue from 'vue';
import {datadogRum} from '@datadog/browser-rum';
import api from '../../api';
import {USER_FORMULAS, Role} from '@animtools/rdx-common';

export const usersModule = {
  namespaced: true,

  state: {
    users: [],
    usersLoading: false,
    user: {},
    currentUserPendingPermissionRequests: [],
    isAdmin: false,
    isHardwareApprover: false,
    isFigureAdmin: false,
    isProjectAdmin: false,
    editingUser: null,
    pendingFigurePermissionRequests: [],
    pendingFigurePermissionRequestsLoading: false,        
    pendingFigurePermissionRequestsPromise: null,
    notifications: {
      loading: false,
      data: [] 
    }
  },

  actions: {
    async getUsers({commit}) {
      commit('setUsersLoading', true);
      try {
        const response = await api.getUsers();
        commit('setUsers', response.data);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;        
      } finally {
        commit('setUsersLoading', false);
      }
    },

    async loadNewEditingUser({ commit, state }, userId) {
      if (userId === state.editingUser?.id) {
        return;
      }
      try {
        const response = await api.getUser(userId);
        commit('setEditingUser', response.data);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;        
      }
    },

    async getCurrentUserPendingPermissionRequests({ commit, state }) {
      const promise = api.getPendingPermissionRequests(state.user.id)
        .then((response) => {
          commit('setCurrentUserPendingPermissionRequests', response.data);
        })
        .catch((error) => {
          commit('pushError', error, {root: true});
        });

      return promise;
    },

    async getPendingFigurePermissionRequests({ commit, state }) {
      if (state.pendingFigurePermissionRequestsPromise) {
        return state.pendingFigurePermissionRequestsPromise;
      }

      commit('setPendingFigureRequestsLoading', true);
      const promise = api.getPendingPermissionRequests()
        .then((response) => {
          commit('setPendingFigureRequests', response.data);
        })
        .catch((error) => {
          commit('pushError', error, {root: true});
        })
        .finally(() => {
          commit('setPendingFigureRequestsPromise', null);
          commit('setPendingFigureRequestsLoading', false);
        });

      commit('setPendingFigureRequestsPromise', promise);
      return promise;
    },

    async addUser({commit, dispatch}, user) {
      try {
        await api.addUser(user);
        dispatch('getUsers');
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },

    async editUser({commit, state}, user) {
      try {
        await api.editUser(user);
        commit('editUser', user);
        if (state.user.sapId === user.sapId) {
          commit('setUser', user);
        }
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },

    async insertFavorite({commit}, figureUuid) {
      try {
        const response = await api.insertFavorite(figureUuid);
        commit('setFavorites', response.data.favorites);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },

    async removeFavorite({commit}, figureUuid) {
      try {
        const response = await api.removeFavorite(figureUuid);
        commit('setFavorites', response.data.favorites);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },
    canEditFigure({state}, figure) {
      return USER_FORMULAS.canEditFigure(figure, state.user);
    },
    canAdminFigure({state}, figure) {
      return USER_FORMULAS.canAdminFigure(figure, state.user);
    },
    canAdminProject({state}, projectName) {
      return USER_FORMULAS.canAdminProject(projectName, state.user);
    },

    async approvePendingFigurePermissionRequest({commit}, requestId) {
      await api.approvePendingFigurePermissionRequest(requestId)
      .then(() => true)
      .catch((error) => {
        console.error(error);
        commit('pushError', error, { root: true });
      });
    },

    async denyPendingFigurePermissionRequest({commit}, {requestId, reasonText}) {
      return api.denyPendingFigurePermissionRequest(requestId, reasonText)
      .then(() => true)
      .catch((error) => {
        console.error(error);
        commit('pushError', error, { root: true });
      });
    },

    async loadCurrentUser({state, commit, dispatch}) {
      if (state.user.id) {
        return;
      }
      dispatch('socket/unsubscribeAllOfType', { type: 'user' }, { root: true })
      await api.getCurrentUserInfo()
      .then(response => {
        this.loading = false;
        commit('setUser', response.data);
        dispatch('socket/subscribe', { type: 'user', id: response.data.id }, { root: true })
        dispatch('astrolabe/getAstrolabeProfiles', [response.data.id], { root: true });
        dispatch('fields/fieldTooltipsGet', null, { root: true });
        dispatch('getCurrentUserPendingPermissionRequests')
      });
    },
    removeRequestFromList({state, commit}, requestId) {
      const newList = state.pendingFigurePermissionRequests.filter((request) => request.id !== requestId)
      commit('setPendingFigureRequests', newList);
    },

    async getNotifications({state, commit}) {
      if (!state.user?.id) {
        return;
      }
      try {
        commit('setNotificationsLoading', true);
        const response = await api.getNotifications();
        commit('setNotifications', response.data);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;        
      } finally {
        commit('setNotificationsLoading', false);
      }
    },

    async deleteNotification({state, commit}, notificationId) {
      try {
        const newList = state.notifications.data.filter((notification) => notification.id !== notificationId)
        commit('setNotifications', newList);
        await api.deleteNotification(notificationId);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;        
      } 
    },

    async setNotificationReadStatus({state, commit}, {notificationId, markAsRead}) {
      try {
        const notification = state.notifications.data.find((notification) => notification.id === notificationId)
        notification.read = markAsRead
        commit('updateNotification', notification);
        if (markAsRead) {
          await api.markNotificationAsRead(notificationId);
        } else {
          await api.markNotificationAsUnread(notificationId);
        }
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;        
      } 
    },

    async setAllNotificationsAsRead({state, commit}) {
    try {
      const unreadNotifications = state.notifications.data.filter((notification) => !notification.read)
      unreadNotifications.forEach((notification) => {
        notification.read = true
        commit('updateNotification', notification);
      });
      await api.markAllNotificationsAsRead();
    } catch (error) {
      commit('pushError', error, {root: true});
      throw error;        
    } 
  },

  async deleteAllNotifications({commit}) {
    try {
      commit('setNotifications', []);
      await api.deleteAllNotifications();
    } catch (error) {
      commit('pushError', error, {root: true});
      throw error;        
    } 
  },

  socket_userPermissionsUpdated({ state, dispatch, commit }, user) {
    if (state.user.id === user.id) {
      commit('setUser', user);
      dispatch('getCurrentUserPendingPermissionRequests');
    }
  },
  
},

  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setUsersLoading(state, loading) {
      state.usersLoading = loading;
    },
    editUser(state, user) {
      const userIndex = state.users.findIndex((i) => i.id == user.id);
      if (userIndex == -1) {
        //This probably just means we haven't loaded the user list yet, so nothing to update
        return;
      }

      Vue.set(state.users, userIndex, user);
    },

    setUser(state, user) {
      state.user = user;
      const userRole = user.role;
      state.isAdmin = (userRole === Role.Admin);
      state.isPowerUser = (userRole === Role.PowerUser);
      state.isHardwareApprover = (userRole === Role.Admin) || (userRole === Role.PowerUser) || user.hardwareApprover;
      state.isFigureAdmin =
        state.isAdmin ||
        state.isPowerUser ||
        user.figureAdmin?.length > 0 ||
        user.projectAdmin?.length > 0;
      state.isProjectAdmin =
        state.isAdmin ||
        state.isPowerUser ||
        user.projectAdmin?.length > 0;
      if (this.state.datadogEnabled) {
        datadogRum.setUser({
          id: user.id,
          name: user.fullName,
          email: user.email,
          sapId: user.sapId,
        });
      }
    },
    setFavorites(state, favorites) {
      state.user.favorites = favorites;
    },
    setEditingUser(state, user) {
      if (user.projectEditor == null) {
        user.projectEditor = [];
      }
      if (user.projectAdmin == null) {
        user.projectAdmin = [];
      }
      if (user.figureEditor == null) {
        user.figureEditor = [];
      }
      if (user.figureAdmin == null) {
        user.figureAdmin = [];
      }
      state.editingUser = user;
    },
    setCurrentUserPendingPermissionRequests(state, requests) {
      state.currentUserPendingPermissionRequests = requests;
    },
    addCurrentUserPendingPermissionRequest(state, request) {
      state.currentUserPendingPermissionRequests = [...state.currentUserPendingPermissionRequests, request];
    },
    setPendingFigureRequests(state, requests) {
      state.pendingFigurePermissionRequests = requests;
    },
    setPendingFigureRequestsLoading(state, loading) {
      state.pendingFigurePermissionRequestsLoading = loading;
    },
    setPendingFigureRequestsPromise(state, promise) {
      state.pendingFigurePermissionRequestsPromise = promise
    },

    setNotificationsLoading(state, loading) {
      state.notifications.loading = loading;
    },

    setNotifications(state, notifications) {
      state.notifications.data = notifications;
    },

    updateNotification(state, notification) {
      const index = state.notifications.data.findIndex((i) => i.id === notification.id);
      if (index === -1) {
        console.log('Could not find notification', notification);
        return;
      }
      Vue.set(state.notifications.data, index, notification);
    },

    //----------------------------
    // Socket Messages
    // ----------------------------
    SOCKET_NOTIFICATION_ADDED(state, notification) {
      Vue.set(state.notifications.data, state.notifications.data.length, notification);
    },
    SOCKET_NOTIFICATION_UPDATED(state, notification) {
      const index = state.notifications.data.findIndex((i) => i.id === notification.id);
      if (index === -1) {
        console.log('Could not find notification', notification);
        return;
      }
      Vue.set(state.notifications.data, index, notification);
    }
  },

  getters: {
    getInitials: (state) => () => {
      const userName = state.user?.fullName || state.user.fullName;
      return (
        userName
            ?.split(' ')
            .map((name) => name[0])
            .join('')
            .slice(0, 2) || ''
      );
    }
  }
};
