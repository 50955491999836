import Vue from 'vue';
import api from '../../api';
import {
  updateBaymaxId,
  updateActuatorsOnFunctionChange,
  updateFunctionsOnActuatorChange,
} from '../utils';
import * as _ from 'lodash';
import type { IActuator, IFunction, IHeader } from '@animtools/rdx-common';

export const figuresModule = {
  namespaced: true,

  state: {
    figures: [],
    figureFiles: [],
    figuresLoading: false,
    figuresError: {},
    figureFunctions: [],
    figureActuators: [],
    history: {
      loading: false,
      data: [] 
    },
    figure: {
      data: {},
      loading: false,
    },
    figureNotificationSettings: {
      data: {},
      loading: false,
    },
    functions: {
      data: [],
      loading: false,
      changes: {},
    },
    functionHistory: {},
    actuators: {
      data: [],
      loading: false,
      changes: {},
    },
    actuatorHistory: {},
    trackings: {
      data: [],
      loading: false,
    },

    currentFunction: {},

    changelog: [],

    functionsMap: {},
    actuatorsMap: {},

    historyDialog: {
      isOpen: false,
      field: null,
      item: null,
    },
  },

  getters: {
    // TODO: This shouldn't need to be done.
    // At the very least there should be a switch here to only pass back "approved" state.
    getStateByType: (state) => (type) => {
      return state[type];
    },

    isFieldChanged: (state) => (type, item, field) => {
      const key = `${item.uuid}_${field.value}`;
      return state[type]?.changes[key] != null;
    },

    numberOfChangesForItem: (state) => (type, item) => {
      const changeKeys = Object.keys(state[type]?.changes);
      const itemChanges = changeKeys.filter((change) => state[type]?.changes[change] && change.startsWith(item.uuid));
      return itemChanges.length;
    },

    numberOfChangesByType: (state) => (type) => {
      const changeKeys = Object.keys(state[type]?.changes || {});
      const itemChanges = changeKeys.filter((change) => state[type]?.changes[change]);
      return itemChanges.length;
    },

    getFigureActuators: (state) => (figureId) => {
      return state.actuators?.data?.filter((a) => a.figureUuid === figureId);
    },

    getFigureFunctions: (state) => (figureId) => {
      return state.functions?.data?.filter((f) => f.figureUuid === figureId);
    },

  },

  actions: {
    figuresGet({commit}) {
      commit('setFiguresError', {});
      commit('setFiguresLoading', true);
      api
          .getFigures()
          .then((response) => {
            commit('setFiguresLoading', false);
            commit('setFigures', response.data);
          })
          .catch((error) => {
            commit('setFiguresLoading', false);
            commit('setFiguresError', error.response.data);
          });
    },

    figureEdit({commit}, {figureId, changes}) {
      return api
        .editFigure(figureId, changes)
        .then(() => true)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    figureNotificationSettingsGet({state, commit}) {
      if (!state.figure?.data?.uuid) {
        return;
      }
      commit('setFigureNotificationSettingsLoading', true);
      api.getNotificationSettingsByFigure(state.figure.data.uuid)
        .then((response) => {
          commit('setFigureNotificationSettingsLoading', false);
          commit('setFigureNotificationSettings', response.data);
        })
        .catch((error) => {
          commit('setFigureNotificationSettingsLoading', false);
          commit('pushError', error, { root: true });
        });
    },

    copyItemsGet({ commit }, { figureUuid, item }) {
      const apiMethods = {
        function: api.getFunctionsForFigure,
        actuator: api.getActuatorsForFigure
      }

      return apiMethods[item](figureUuid)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          commit('pushError', error, { root: true });
        });
    },

    getCommitHistory({ commit }, { figureUuid }) {
      commit('setHistory', []);
      commit('setHistoryLoading', true);
      return api.getFigureHistory(figureUuid)
        .then((response) => {
          commit('setHistory', response.data);
        })
        .catch((error) => {
          commit('setFiguresError', error.response.data);
          commit('setHistoryLoading', false);
        });
    }, 

    requestFigurePermissionUpgrade({ commit }, { figureUuid, permissionLevel, requestFullProject, reason }) {
      return api.requestFigurePermissionUpgrade(figureUuid, permissionLevel, requestFullProject, reason)
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    functionAdd({ commit }, { figureUuid, changes }) {
      const figure = { uuid: figureUuid };
      const functionDto = { figure, ...changes };

      const payload = { data: functionDto, message: `Added Function ${changes.name}`};
      return api
        .addFunction(payload)
        .then((response) => response)
        .catch((error) => {
          console.log(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    functionEdit({commit}, {uuid, changes}) {
      if (!uuid) {
        commit('pushError', 'Invalid function uuid:' + uuid, {root: true});
      }
      return api
        .editFunction(uuid, changes)
        .then(() => true)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    functionsEdit({commit}, {changes, changelog}) {
      const payload = {data: changes, message: changelog};
      return api
        .editFunctions(payload)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    functionsGet({commit}, figureId) {
      commit('setFunctionsLoading', true);
      return api
        .getFunctionsForFigure(figureId)
        .then((response) => {
          commit('setFunctions', response.data);
          commit('setFigureFunctions', response.data);
          return true;
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        })
        .finally(() => {
          commit('setFunctionsLoading', false);
        });
    },

    functionsMapGet({ commit }) {
      return api
        .getFunctionsMap()
        .then((response) => {
          commit('setFunctionsMap', response.data);
          return true;
        })
        .catch((error) => {
          console.log(error);
          commit('pushError', error, { root: true });
        });
    },

    functionFieldHistory({commit}, {uuid, field}) {
      return api
          .getFunctionFieldHistory(uuid, field)
          .then(({data}) => {
            commit('setFunctionHistory', {field, data});
          })
          .catch((error) => {
            console.error(error);
            commit('pushError', error, {root: true});
          });
    },

    functionFieldsHistoryBulk({commit}, {uuid, fields}) {
      return api
          .getFunctionFieldsHistoryBulk(uuid, fields)
          .then(({data}) => {
            commit('setFunctionHistoryBulk', {fields, data});
          })
          .catch((error) => {
            console.error(error);
            commit('pushError', error, {root: true});
          });
    },

    actuatorsMapGet({ commit }) {
      return api
        .getActuatorsMap()
        .then((response) => {
          commit('setActuatorsMap', response.data);
          return true;
        })
        .catch((error) => {
          console.log(error);
          commit('pushError', error, { root: true });
        });
    },

    actuatorsGet({commit}, figureId) {
      commit('setActuatorsLoading', true);
      return api
        .getActuatorsForFigure(figureId)
        .then((response) => {
          response.data.forEach((a) => updateBaymaxId(a));
          commit('setActuators', response.data);
          commit('setFigureActuators', response.data);
          return true;
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        })
        .finally(() => {
          commit('setActuatorsLoading', false);
        });
    },

    actuatorAdd({commit}, {figureUuid, changes}) {
      const figure = {uuid: figureUuid};
      const actuator = {figure, ...changes};

      const payload = {data: actuator, message: 'Actuator Added'};
      return api
        .addActuator(payload)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    actuatorEdit({commit}, {uuid, changes}) {
      return api
        .editActuator(uuid, changes)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    actuatorsEdit({commit}, {changes, changelog}) {
      const payload = {data: changes, message: changelog};
      return api
        .editActuators(payload)
        .then((response) => response)
        .catch((error) => {
          commit('pushError', error, { root: true });
          return false;
        });
    },

    actuatorDelete({commit}, {uuid, changes}) {
      return api
        .deleteActuator(uuid, changes)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    actuatorFieldHistory({commit}, {uuid, field}) {
      return api
          .getActuatorFieldHistory(uuid, field)
          .then(({data}) => {
            commit('setActuatorHistory', {field, data});
          })
          .catch((error) => {
            console.error(error);
            commit('pushError', error, {root: true});
          });
    },
    actuatorFieldsHistoryBulk({commit}, {uuid, fields}) {
      return api
          .getActuatorFieldsHistoryBulk(uuid, fields)
          .then(({data}) => {
            commit('setActuatorHistoryBulk', {fields, data});
          })
          .catch((error) => {
            console.error(error);
            commit('pushError', error, {root: true});
          });
    },

    clearActuatorFieldHistory({commit}) {
      commit('clearActuatorHistory');
    },

    filesEdit({commit}, item) {
      commit('setEditedFiles', item);
      commit('setDialog', true, {root: true});
    },
    changelogGet({commit}, scope) {
      commit('setChangelog', []);
      return api
        .getChangelog(scope)
        .then((response) => {
          commit('setChangelog', response.data);
          return true;
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    trackingsCreate({commit}, {changes}) {
      const payload = {data: changes};
      return api
        .createTrackings(payload)
        .then(() => true)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    trackingsEdit({commit}, {changes}) {
      const payload = {data: changes};
      return api
        .editTrackings(payload)
        .then(() => true)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
          return false;
        });
    },

    insertChipInFigure({commit}, chipInfo) {
      const {chipUuid, figureUuid} = chipInfo;
      return api
        .insertChipInFigure(chipUuid, figureUuid)
        .then((response) => {
          commit('setFigureChips', response.data.chips);
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    removeChipInFigure({commit}, chipInfo) {
      const {chipUuid, figureUuid} = chipInfo;
      return api
        .removeChipInFigure(chipUuid, figureUuid)
        .then((response) => {
          commit('setFigureChips', response.data.chips);
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    figureExcelFilename({state}) {
      const exportTime = new Date()
          .toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC'});
      const attraction = state.figure.data.info.attraction.replaceAll(' ', '-');
      const time = exportTime.replace(', ', '-').replace(/ |\/|:/g, '-');
      return `${attraction}_${state.figure.data.name}_${time}.xlsx`;
    },

    setHistoryDialog({ commit }, { item, field, isOpen }) {
      commit('setHistoryDialogItem', item);
      commit('setHistoryDialogField', field);
      commit('setHistoryDialogIsOpen', isOpen);
    },

    loadNewFigureByUuid({ commit, dispatch, state }, figureUuid) {
      if (figureUuid === state.figure.figureUuid) {
        return;
      }
      commit('setFigureLoading', true);
      commit('setFigure', { figureUuid: figureUuid, data: {}, loading: true });
      dispatch('socket/unsubscribeAllOfType', { type: 'figure' }, { root: true })
      return dispatch('socket/subscribe', { type: 'figure', id: figureUuid }, { root: true });
    },

    async setRecentlyViewedFigure({ commit }, figureUuid) {
      try {
        const response = await api .setRecentlyViewedFigure(figureUuid)
        commit('setFigureChips', response.data.chips);
      } catch (error) {
        console.error(error);
        commit('pushError', error, { root: true });
      }
    },

    socket_figureSet({ state, commit, dispatch }, figure) {
      if (figure.uuid !== state.figure.figureUuid) {
        return;
      }

      if (state.figure?.data?.uuid === figure.uuid) {
        commit('updateFigureData', figure);
      } else {
        commit('setFigureData', figure);

        dispatch('actuatorsGet', figure.uuid);
        dispatch('functionsGet', figure.uuid);
        dispatch('trackings/trackingsGet', figure.uuid, { root: true });
      }
      commit('setFigureFiles', figure.files);
      commit('setFigureLoading', false);
    },

  },

  mutations: {
    setFigures(state, array) {
      state.figures = array;
    },

    setFiguresLoading(state, loading) {
      state.figuresLoading = loading;
    },

    setFiguresError(state, error) {
      state.figuresError = error;
    },

    setHistory(state, history) {
      state.history.data = history || [];
      state.history.loading = false
    },

    setHistoryLoading(state, loading) {
      state.history.loading = loading;
    },


    setFigure(state, figure) {
      state.figure = figure;
    },

    setFigureData(state, figure) {
      state.figure.data = figure;
    },

    updateFigureData(state, figure) {
      Object.assign(state.figure.data, figure);
    },

    setFigureFiles(state, files) {
      state.figureFiles = files;
    },

    setFigureLoading(state, loading) {
      state.figure.loading = loading;
    },

    setFigureNotificationSettings(state, figureNotificationSettings) {
      state.figureNotificationSettings.data = figureNotificationSettings ?? {};
    },

    setFigureNotificationSettingsLoading(state, loading) {
      state.figureNotificationSettings.loading = loading;
    },

    clearFigureNotificationSettings(state) {
      state.figureNotificationSettings.data = {};
    },

    setFigureChips(state, chips) {
      state.figure.chips = chips;
    },

    setFunction(state, func) {
      state.currentFunction = func;
    },

    setFunctions(state, functions) {
      state.functions.data = functions || [];
      state.functions.loading = false
    },

    setFunctionsLoading(state, loading) {
      state.functions.loading = loading;
    },

    setFunctionsMap(state, map) {
      state.functionsMap = map;
    },

    setFunctionHistory(state, {field, data}) {
      state.functionHistory[field] = data;
    },

    setFunctionHistoryBulk(state, { fields, data }) {
      fields.forEach((field) => {
        state.functionHistory[field] = data[field];
      });
    },

    setEditedFiles(state, item) {
      state.editedFiles = item;
    },

    // DEPRECATED - used by RDXTable
    editFunctions(state, functions) {
      for (const function_ of functions) {
        const index = state.functions.data.findIndex(
            (i) => i.uuid == function_.uuid,
        );
        if (index === -1) {
          console.log('Could not find function', function_);
          continue;
        }
        Vue.set(state.functions.data, index, function_);
        updateActuatorsOnFunctionChange(function_, state.actuators.data);
      }
    },

    setActuators(state, actuators) {
      state.actuators.data = actuators || [];
      state.actuators.loading = false;
    },

    setActuatorsLoading(state, loading) {
      state.actuators.loading = loading;
    },

    setActuatorHistory(state, {field, data}) {
      state.actuatorHistory[field] = data;
    },

    setActuatorHistoryBulk(state, { fields, data }) {
      fields.forEach((field) => {
        state.actuatorHistory[field] = data[field];
      });
    },

    setActuatorsMap(state, map) {
      state.actuatorsMap = map;
    },

    clearActuatorHistory(state) {
      state.actuatorHistory = {};
    },

    setChangelog(state, changelog) {
      state.changelog = changelog;
    },

    setTrackings(state, trackings) {
      state.trackings.data = trackings || [];
      state.trackings.loading = false
    },

    setTrackingsLoading(state, loading) {
      state.trackings.loading = loading;
    },

    setFigureFunctions(state, functions) {
      state.figureFunctions = functions;
    },

    setFigureActuators(state, actuators) {
      state.figureActuators = actuators;
    },

    setFunctionChange(state, { item, field, value }) {
      const index = state.functions.data
        .findIndex((i) => i.uuid == item.uuid);
      if (index === -1) {
        console.log('Could not find function', item);
        return;
      }
      const changeKey = `${item.uuid}_${field.value}`;
      const changes = state.functions.changes[changeKey];
      const originalValue = changes ?
        changes.originalValue : _.cloneDeep(state.functions.data[index][field.value]);

      let changeValue = null;
      if (!_.isEqual(value, originalValue)) {
        changeValue = {
          originalValue,
          newValue: value,
        };
      }

      Vue.set(state.functions.data[index], field.value, value);
      if (changeValue === null) {
        Vue.delete(state.functions.changes, changeKey);
      } else {
        Vue.set(state.functions.changes, changeKey, changeValue);
      }
      updateActuatorsOnFunctionChange(item, state.actuators.data);
    },

    undoChangeByType(state, type) {
      Object.keys(state[type].changes)
        .map(key => {
          const [uuid, field] = key.split('_');
          return { uuid, field, key };
        })
        .forEach(({ uuid, field, key }) => {
          const index = state[type].data
            .findIndex((item) => item.uuid === uuid);
          const { originalValue } = state[type].changes[key]
          Vue.set(state[type].data[index], field, originalValue);
        });

      Vue.set(state[type], 'changes',  {});
    },

    clearCommittedChanges(state, type) {
      Vue.set(state[type], 'changes',  {});
      state.changelog = '';
    },

    setActuatorChange(state, { item, field, value }) {
      const index = state.actuators.data
        .findIndex((i) => i.uuid == item.uuid);
      if (index === -1) {
        console.log('Could not find actuator', item);
        return;
      }
      const changeKey = `${item.uuid}_${field.value}`;
      const changes = state.actuators.changes[changeKey];
      const originalValue = changes ?
        changes.originalValue : _.cloneDeep(state.actuators.data[index][field.value]);

      let changeValue = null;
      if (!_.isEqual(value, originalValue)) {
        changeValue = {
          originalValue,
          newValue: value,
        };
      }

      Vue.set(state.actuators.data[index], field.value, value);
      if (changeValue === null) {
        Vue.delete(state.actuators.changes, changeKey);
      } else {
        Vue.set(state.actuators.changes, changeKey, changeValue);
      }
      updateFunctionsOnActuatorChange(item, state.functions.data);
    },

    setHistoryDialogItem(state, newValue: IActuator|IFunction) {
      Vue.set(state.historyDialog, 'item', newValue);
    },
    setHistoryDialogField(state, newValue: IHeader) {
      Vue.set(state.historyDialog, 'field', newValue);
    },
    setHistoryDialogIsOpen(state, newValue: boolean) {
      Vue.set(state.historyDialog, 'isOpen', newValue);
    },

    //----------------------------
    // Socket Messages
    // ----------------------------
    SOCKET_FUNCTIONS_UPDATED(state, functions) {
      for (const function_ of functions) {
        const index = state.functions.data.findIndex(
            (i) => i.uuid == function_.uuid,
        );
        if (index === -1) {
          console.log('Could not find function', function_);
          continue;
        }
        Vue.set(state.functions.data, index, function_);
        updateActuatorsOnFunctionChange(function_, state.actuators.data);
      }
    },

    SOCKET_ACTUATOR_CREATED(state, actuator) {
      updateBaymaxId(actuator);
      state.actuators.data.push(actuator);
    },

    SOCKET_ACTUATORS_UPDATED(state, actuators) {
      for (const actuator of actuators) {
        const index = state.actuators.data.findIndex(
            (i) => i.uuid == actuator.uuid,
        );
        if (index === -1) {
          console.log('Could not find actuator', actuator);
          continue;
        }
        updateBaymaxId(actuator);
        Vue.set(state.actuators.data, index, actuator);
        updateFunctionsOnActuatorChange(actuator, state.functions.data);
      }
    },

    SOCKET_ACTUATOR_UPDATED(state, actuator) {
      const index = state.actuators.data.findIndex(
          (i) => i.uuid == actuator.uuid,
      );
      if (index === -1) {
        console.log('Could not find actuator', actuator);
        return;
      }
      updateBaymaxId(actuator);
      Vue.set(state.actuators.data, index, actuator);
      updateFunctionsOnActuatorChange(actuator, state.functions.data);
    },

    SOCKET_ACTUATOR_DELETED(state, actuator) {
      const index = state.actuators.data.findIndex(
          (i) => i.uuid == actuator.uuid,
      );
      if (index === -1) {
        console.log('Could not find actuator', actuator);
        return;
      }
      Vue.delete(state.actuators.data, index);
      updateFunctionsOnActuatorChange(actuator, state.functions.data);
    },

    SOCKET_FUNCTION_UPDATED(state, function_) {
      const index = state.functions.data.findIndex(
          (i) => i.uuid == function_.uuid,
      );
      if (index === -1) {
        console.log('Could not find function', function_);
        return;
      }
      Vue.set(state.functions.data, index, function_);
      updateActuatorsOnFunctionChange(function_, state.actuators.data);
    },

    SOCKET_TRACKINGS_CREATED(state, trackings) {
      state.trackings.data = state.trackings.data.concat(trackings);
    },

    SOCKET_TRACKINGS_UPDATED(state, trackings) {
      for (const tracking of trackings) {
        const index = state.trackings?.data.findIndex(
            (i) => i.uuid == tracking.uuid,
        );
        if (index === -1) {
          console.log('Could not find tracking', tracking);
          continue;
        }
        Vue.set(state.trackings.data, index, tracking);
      }
    },
  },
};
